
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';
import ThemenschwerpunktKalenderComponent from '@/components/ThemenschwerpunktKalenderComponent.vue';

export default defineComponent({
  components: {
    ThemenschwerpunktKalenderComponent,
  },
  setup() {
    const navigateHook = (navigate: any) => {
      navigate();
    };
    return {
      navigateHook,
    };
  },
});
