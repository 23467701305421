<style lang="scss">
.themenschwerpunktkalender {
  .head-row-text-normal {
    .text-normal {
      display: inline-block;
      text-transform: none !important;
    }
  }

  .portfolio-box {
    margin-top: 50px;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }
  }
  .bild-box {
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .leistung {
    strong {
      display: block;
      font-size: 140%;
      text-transform: uppercase;
    }
    &:before {
      content: '';
      display: block;
      margin: 20px 0;
      width: 150px;
      height: 5px;
      background: $blue-darker;
    }
    &:after {
      content: '';
      display: block;
      margin: 20px 0;
      width: 100px;
      height: 5px;
      background: $blue-darker;
    }
  }
  .text-center-small {
    @media (max-width: 992px) {
      text-align: center !important;
    }
  }
  .content-link-mehrinfo {
    color: #fff !important;
  }
  .format-box {
    margin-bottom: 20px;
    .col-lg-5,
    .col-lg-3 {
      justify-content: center;
      align-self: center;
    }
    img {
      display: block;
      margin: 0 auto;
      max-height: 120px;
    }
    .tabelle-box {
      .headline {
        text-transform: none;
        strong {
          display: block;
          text-transform: uppercase;
        }
      }
      .head-row {
        font-size: 80%;
      }
    }
  }
  .alignItems {
    [class*='col-'] {
      justify-content: center;
      align-self: center;
    }
  }
  .router-link-exact-active {
    &:after {
      display: none;
    }
  }
}
</style>
<template>
  <div class="themenschwerpunktkalender content container">
    <div class="row">
      <div class="col-lg-12">
        <h1>Themenschwerpunktkalender</h1>
      </div>
    </div>
  </div>
  <ThemenschwerpunktKalenderComponent />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';
import ThemenschwerpunktKalenderComponent from '@/components/ThemenschwerpunktKalenderComponent.vue';

export default defineComponent({
  components: {
    ThemenschwerpunktKalenderComponent,
  },
  setup() {
    const navigateHook = (navigate: any) => {
      navigate();
    };
    return {
      navigateHook,
    };
  },
});
</script>
